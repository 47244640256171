import { Dialog } from "@radix-ui/react-dialog";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../dialog";
import {
  FC,
  memo,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "../../button";
import { PlusCircle, XCircle } from "lucide-react";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import EventFormSchema from "./EventFormSchema";
import Axios from "axios";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../form";
import { Input } from "../../input";
import moment from "moment";
import { Icon, IconNames } from "../../icon/icon";
import DialogCommonFooter from "../DialogCommonFooter";
import CommonSingleSelect from "./CommonSingleSelect";
import dialogUiConfig from "./dialogUiConfig";
import AddPlaylistContentDialog from "../AddPlaylistContentDialog";
import { AuthContext } from "app/providers/auth";
import RepetitionSelector from "./RepetitionSelector";
import { useSettings } from "app/hooks/settings";
import { customIconsConf, customIconsNames } from "@/config";

type AddEventDialogPropsType = {
  patientId: string;
  disabled: boolean;
  CustomDialogTrigger?: ReactElement<any>;
  selectedEvent?: any; // !TODO
  open?: boolean,
  onOpenClose?: (open: boolean) => void;
  onSubmit?: (errMsg: string | null, successMsg?: string) => void;
  newEventDate?: Date,
};

const AddUpdateEventDialog: FC<AddEventDialogPropsType> = ({
  patientId,
  disabled,
  CustomDialogTrigger,
  selectedEvent,
  open = false,
  onOpenClose,
  onSubmit,
  newEventDate,
}) => {
  const [settings] = useSettings();
  const [popoverOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataFetching, setDataDetching] = useState(false);
  const authContext = useContext(AuthContext);
  // !FIXME
  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault() 
  }, []);

  const form = useForm<z.infer<typeof EventFormSchema>>({
    resolver: zodResolver(EventFormSchema),
    defaultValues: {
      title: selectedEvent?.title ?? "",
      start_date: selectedEvent?.start_date ?? moment(newEventDate).toISOString(),
      end_date: selectedEvent?.end_date ?? moment(newEventDate).add(1, "hour").toISOString(),
      event_type: selectedEvent?.event_type ?? "other",
      icon: selectedEvent?.icon ?? "",
      icon_type: selectedEvent?.icon_type ?? "default",
      metadata: selectedEvent?.metadata ?? null,
      reminder: selectedEvent?.reminder ?? 15,
      repeat: selectedEvent?.repeat ?? "daily",
      repeat_by_weekday: selectedEvent?.repeat_by_weekday ?? null,
      repeat_interval: selectedEvent?.repeat_interval ?? 1,
      repeat_end_date: selectedEvent?.repeat_end_date ?? moment(newEventDate).add(1, "year").toISOString(),
      account_id: authContext.user?.account_id,
      patient_id: patientId,
    },
  });

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);
   
    if (open) form.reset();
   
    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    onOpenChange(open);
  
    form.reset({
      title: selectedEvent?.title ?? "",
      start_date: selectedEvent?.start_date ?? moment(newEventDate).toISOString(),
      end_date: selectedEvent?.end_date ?? moment(newEventDate).add(1, "hour").toISOString(),
      event_type: selectedEvent?.event_type ?? "other",
      icon: selectedEvent?.icon ?? "",
      icon_type: selectedEvent 
      ? (selectedEvent.icon_type ?? "default") 
      : ((typeof settings === "object" && settings.categories_icons_dataset) ?? "default"),
      metadata: selectedEvent?.metadata ?? null,
      reminder: selectedEvent?.reminder ?? 15,
      repeat: selectedEvent?.repeat ?? "daily",
      repeat_by_weekday: selectedEvent?.repeat_by_weekday ?? null,
      repeat_interval: selectedEvent?.repeat_interval ?? 1,
      repeat_end_date: selectedEvent?.repeat_end_date ?? moment(newEventDate).add(1, "year").toISOString(),
      account_id: authContext.user?.account_id,
      patient_id: patientId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedEvent, newEventDate, settings]);

  const uiConfig = !!selectedEvent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof EventFormSchema>) => {
    try {
      setDataDetching(true);

      console.log("submit form >>>>", formData); // !DEBUG
      // return;
      const { status: calendarEventStatus } = await Axios({
        method: !!selectedEvent ? "PATCH" : "POST",
        url: !!selectedEvent
          ? "/api/calendar/" + selectedEvent.id
          : "/api/calendar",
        data: formData,
      });

      if (calendarEventStatus !== 201 && calendarEventStatus !== 200) {
        throw new Error("Error creating category");
      }

      onOpenChange(false);
      onSubmit?.(null, uiConfig.submitSuccessMessage);
      
    } catch (err) {
      console.error(err);
      onSubmit?.(uiConfig?.submitErrorMessage);
    } finally {
      setDataDetching(false);
    }
  };

  const onRemoveEvent = async () => {
    if (
      !window.confirm(
        t("mediaLibraryScreen.contentForm.deleteConfirmation", {
          name: selectedEvent.title,
        })
      )
    ) {
      return;
    }

    try {
      setDataDetching(true);
      await Axios.delete(`/api/calendar/${selectedEvent?.id}`);
      onOpenChange(false);
      onSubmit?.(null, uiConfig.submitSuccessMessage);
    } catch(err) {
      console.error(err);
      onSubmit?.(uiConfig?.submitErrorMessage);
    } finally {
      setDataDetching(false);
    }
  };

  const selectIconsOptions = useMemo(() => {
    if (typeof settings !== "object") {
      return [];
    }

    const datasetUsedBySettings = settings.categories_icons_dataset || "default";

    if (!selectedEvent
      || selectedEvent.icon_type === datasetUsedBySettings
      || (!selectedEvent.icon_type && datasetUsedBySettings === "default")
    ) {
      return datasetUsedBySettings === "default"
        ? IconNames
        : customIconsNames;
    }

    return (datasetUsedBySettings === "default")
      ? [selectedEvent.icon, ...IconNames]
      : [selectedEvent.icon, ...customIconsNames];
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, selectedEvent]);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        {CustomDialogTrigger || (
          <Button
            className="gap-1 focus-visible:ring-0 focus:ring-0"
            variant="secondary"
            aria-haspopup="true"
            size="default"
          >
            <PlusCircle className="h-3.5 w-3.5" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              {t("patientsScreen.schedule.addActivity")}
            </span>
          </Button>
        )}

      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        className="flex flex-col w-[640px] min-w-[800px] h-[600px]"
      >
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <div className="flex w-full border-0 border-red-300">
              {/* left side (name) ===> */}
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300">
                <FormField
                  disabled={dataFetching}
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] truncate text-[#11203D] font-medium"
                        htmlFor="idEventTitle"
                      >
                        {dialogUiConfig.eventTitleFieldLabel}
                      </FormLabel>
                      <FormControl id="idEventTitle">
                        <Input
                          disabled={dataFetching}
                          {...field}
                          className="focus-visible:ring-0"
                          id="idEventTitle"
                          placeholder={dialogUiConfig.eventTitleFieldPlaceholder}
                        />
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              {/* right side (icon) ===>  */}
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 ml-4">
                {/* === icon === */}
                <FormField
                  disabled={dataFetching}
                  control={form.control}
                  name="icon"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] truncate text-[#11203D] font-medium"
                        htmlFor="folderIcon"
                      >
                        {dialogUiConfig.eventIconFieldLabel}
                      </FormLabel>
                      <FormControl id="folderIcon">
                        <CommonSingleSelect
                          placeholder={dialogUiConfig.eventIconFieldPlaceholder}
                          disabled={dataFetching}
                          defaultValue={field.value}
                          options={dialogUiConfig.getIconOptions(selectIconsOptions)}
                          onValueChanged={(val) => {
                            if (typeof settings === "object") {
                              form.setValue("icon_type", settings.categories_icons_dataset || "default")
                            }
                            field.onChange(val);
                          }}
                          renderOption={({ value }) => {
                            if (typeof settings !== "object") return (<div/>);
                            const datasetUsedBySettings = settings.categories_icons_dataset || "default";
                            const iconTypeOnForm = form.getValues("icon_type");

                            if (!selectedEvent
                              || value !== field.value
                              || datasetUsedBySettings === iconTypeOnForm
                            ) {
                              if (datasetUsedBySettings === "default") {
                                return (
                                  <Icon
                                    pack="innocom"
                                    color="#020817"
                                    image={`icon-${value}`}
                                    className="!text-[32px] py-2"
                                  />
                                );
                              }

                              const icPath = customIconsConf[value as keyof typeof customIconsConf];

                              return (
                                <div
                                  className="w-[32px] h-[36px] bg-cover bg-center bg-no-repeat border-0"
                                  style={{ backgroundImage: `url(${icPath}` }}
                                />
                              );
                            }

                            if (iconTypeOnForm === "default") {
                              return (
                                <Icon
                                  pack="innocom"
                                  color="#020817"
                                  image={`icon-${value}`}
                                  className="!text-[32px] py-2"
                                />
                              );
                            }
                            return (
                              <div
                                className="w-[32px] h-[36px] bg-cover bg-center bg-no-repeat border-0"
                                style={{ backgroundImage: `url(/icons/custom_categories/${value}.jpg)` }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex w-full border-0 border-red-300">
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 mr-4">
                <FormField
                  disabled={dataFetching}
                  name="start_date"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] truncate text-[#11203D] font-medium"
                        htmlFor="idEventStartDate"
                      >
                        {dialogUiConfig.eventStartDateFieldLabel}
                      </FormLabel>
                      <FormControl id="idEventStartDate">
                        <Controller
                          {...field}
                          render={({ field: { onChange, onBlur, value }}) => {
                            return (
                              <input
                                disabled={dataFetching}
                                onBlur={onBlur}
                                value={moment(value).format("HH:mm")}
                                className="border-[1px] h-[40px] px-2 rounded-md block w-full"
                                type="time"
                                onChange={(e) => {
                                  const mTime = moment(e.target.value, "HH:mm");
                                  const mm = moment(value).set({
                                    hour: mTime.get("hour"),
                                    minute: mTime.get("minute"),
                                  });
                                  onChange(mm.toISOString());
                                  form.setValue("end_date", mm.add(1, "hour").toISOString() as unknown as Date)
                                  form.setValue("repeat_end_date", mm.add(1, "year").toISOString() as unknown as Date)
                                }}
                              />
                            );
                          }}>
                        </Controller>
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 ml-4">
                {/* === end datetime === */}
              <FormField
                  disabled={dataFetching}
                  name="end_date"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] truncate text-[#11203D] font-medium"
                        htmlFor="idEventEndDate"
                      >
                        {dialogUiConfig.eventEndDateFieldLabel}
                      </FormLabel>
                      <FormControl id="idEventEndDate">
                        <Controller
                          {...field}
                          render={({ field: { onChange, onBlur, value }}) => {
                            return (
                              <input
                                disabled={dataFetching}
                                onBlur={onBlur}
                                value={moment(value).format("HH:mm")}
                                className="border-[1px] h-[40px] px-2 rounded-md block w-full"
                                type="time"
                                onChange={(e) => {
                                  const mTime = moment(e.target.value, "HH:mm");
                                  const mm = moment(value).set({
                                    hour: mTime.get("hour"),
                                    minute: mTime.get("minute"),
                                  });
                                  onChange(mm.toISOString());
                                }}
                              />
                            );
                          }}>
                        </Controller>
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* === reminder and repetition === */}
            <div className="flex w-full border-0 border-red-300">
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 mr-4">
                {/* === reminder === */}
                <FormField
                  disabled={dataFetching}
                  name="reminder"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] truncate text-[#11203D] font-medium"
                        htmlFor="idReminder"
                      >
                        {dialogUiConfig.eventReminderFieldLabel}
                      </FormLabel>
                      <FormControl id="idReminder">
                        <Controller
                          {...field}
                          render={({ field: { onChange, value }}) => (
                            <CommonSingleSelect
                              disabled={dataFetching}
                              defaultValue={value.toString()}
                              options={dialogUiConfig.reminderOptions}
                              onValueChanged={(value) => onChange(parseInt(value, 10))}
                            />
                          )}
                        />
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex w-11/12 min-w-11/12 max-w-11/12 overflow-hidden border-0 border-green-300 ml-2">
                <div className="flex w-full min-w-full max-w-full overflow-hidden">
                  <FormField
                    disabled={dataFetching}
                    name="repeat"
                    render={({ field }) => (
                      <FormItem className="mb-[4px] w-full">
                        <FormLabel
                          className="mb-[6px] truncate text-[#11203D] font-medium"
                          htmlFor="idRepeat"
                        >
                          {dialogUiConfig.eventRepeatFieldLabel}
                        </FormLabel>
                        <FormControl id="idRepeat">
                          <Controller
                            {...field}
                            render={({ field: { onChange, value }}) => (
                              <RepetitionSelector
                                disabled={disabled}
                                onUpdateRepeatType={onChange}
                                defaultSelectedRepeatType={value}
                                defaultSelectedWeekDays={form.watch("repeat_by_weekday") || []}
                                // !FIXME
                                onUpdateSelectedWeekdays={(weekdays) => form.setValue("repeat_by_weekday", weekdays as any)}
                              />
                            )}
                          />
                        </FormControl>
                        <div className="flex h-[20px] overflow-hidden">
                          <FormMessage className="truncate" />
                        </div>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>


              {/* remove */}
              {/* <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 ml-4">
                <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden">
                  <FormField
                    disabled={dataFetching}
                    name="repeat"
                    render={({ field }) => (
                      <FormItem className="mb-[4px] w-full">
                        <FormLabel
                          className="mb-[6px] truncate text-[#11203D] font-medium"
                          htmlFor="idRepeat"
                        >
                          {dialogUiConfig.eventRepeatFieldLabel}
                        </FormLabel>
                        <FormControl id="idRepeat">
                          <Controller
                            {...field}
                            render={({ field: { onChange, value }}) => (
                              <RadioGroup
                                disabled={dataFetching}
                                id="idRepeat"
                                className="border-0 gap-1"
                                defaultValue={value}
                                onValueChange={(v) => {
                                  form.resetField("repeat_by_weekday") // !TODO
                                  onChange(v);
                                }}
                              >
                                {dialogUiConfig.repeatTypeOptions.map((repeatTypeOption) => (
                                  <div
                                    key={repeatTypeOption.value}
                                    className="flex items-center space-x-2 border-0 my-0"
                                  >
                                    <RadioGroupItem
                                      className="text-[#1949a3]"
                                      value={repeatTypeOption.value}
                                      id={`id${repeatTypeOption.value}`}
                                    />
                                    <Label
                                      className="font-light text-sm text-[#11203D]"
                                      htmlFor={`id${repeatTypeOption.value}`}
                                    >
                                      {repeatTypeOption.label}
                                    </Label>
                                  </div>
                                ))}
                              </RadioGroup>
                            )}
                          />
                        </FormControl>
                        <div className="flex h-[20px] overflow-hidden">
                          <FormMessage className="truncate" />
                        </div>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden">
                  {(form.watch("repeat") === "weekly") && (
                    <FormField
                      disabled={dataFetching}
                      name="repeat_by_weekday"
                      render={({ field }) => (
                        <FormItem className="mb-[4px] w-full max-w-full overflow-hidden">
                          <FormLabel
                            className="mb-[6px] truncate text-[#11203D] font-medium"
                            htmlFor="idRepeatByWeekDay">
                            {dialogUiConfig.eventRepeatByWeekDayFieldLabel}
                          </FormLabel>
                          <FormControl id="idRepeat">
                            <Controller
                              {...field}
                              render={({ field: { onChange, value, onBlur }}) => (
                                <CustomMultiSelect
                                  className="w-full max-w-full"
                                  onOpenClose={setPopoverOpen}
                                  placeholder={dialogUiConfig.eventRepeatByWeekDayFieldPlaceholder}
                                  defaultSelectedValues={value || []}
                                  data={dialogUiConfig.repeatByWeekDayOptions}
                                  disabled={dataFetching}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                            />
                          </FormControl>
                          <div className="flex h-[20px] overflow-hidden">
                            <FormMessage className="truncate" />
                          </div>
                        </FormItem>
                      )}
                    />                  
                  )}
                </div>
              </div> */}
            </div>

            {/* ================= event_type ================= */}
            <div className="flex w-full border-0 border-red-300">
              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 mr-4">
                <FormField
                  disabled={dataFetching}
                  name="event_type"
                  render={({ field }) => (
                    <FormItem className="mb-[4px] w-full">
                      <FormLabel
                        className="mb-[6px] text-[#11203D] font-medium"
                        htmlFor="idEventType"
                      >
                        {dialogUiConfig.eventTypeFieldLabel}
                      </FormLabel>
                      <FormControl id="idEventType">
                        <Controller
                          {...field}
                          render={({ field: { onChange, value }}) => (
                            <CommonSingleSelect
                              disabled={dataFetching}
                              defaultValue={value}
                              options={dialogUiConfig.eventTypeOptions}
                              onValueChanged={(value) => {
                                onChange(value);
                                form.resetField("metadata");
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <div className="flex h-[20px] overflow-hidden">
                        <FormMessage className="truncate" />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex w-1/2 min-w-1/2 max-w-1/2 overflow-hidden border-0 border-green-300 ml-4">
                {form.watch("event_type") === "external_source" && (
                  <FormField
                    disabled={dataFetching}
                    control={form.control}
                    name="metadata"
                    render={({ field }) => (
                      <FormItem className="mb-[4px] w-full">
                        <FormLabel
                          className="mb-[6px] text-[#11203D] font-medium"
                          htmlFor="idEventMetadataLink"
                        >
                          {dialogUiConfig.eventUrlFieldLabel}
                        </FormLabel>
                        <FormControl id="idEventMetadataLink">
                          <Controller
                            {...field}
                            render={({ field: { onChange, onBlur, value }}) => {
                              return (
                                <Input
                                  disabled={dataFetching}
                                  onBlur={onBlur}
                                  onChange={(event) => {
                                    onChange({ link: event.target.value });
                                  }}
                                  className="focus-visible:ring-0"
                                  id="idEventMetadataLink"
                                  placeholder={dialogUiConfig.eventUrlFieldPlaceholder}
                                  value={value ? value.link : ""}
                                />
                              );
                            }}
                          />
                        </FormControl>
                        <div className="flex h-[20px] overflow-hidden">
                          <FormMessage className="truncate" />
                        </div>
                      </FormItem>
                    )}
                  />
                )}

                {(form.watch("event_type") === "media") && (
                  <FormField
                    disabled={dataFetching}
                    control={form.control}
                    name="metadata"
                    render={({ field }) => (
                      <FormItem className="mb-[4px] w-full">
                        <FormLabel
                          className="mb-[6px] w-full text-[#11203D] font-medium"
                          htmlFor="idEventMetadataMedia"
                        >  
                          {dialogUiConfig.eventMediaFieldLabel}
                        </FormLabel>
                        <FormControl id="idEventMetadataMedia">
                          <Controller
                            {...field}
                            render={({ field: { onChange, value }}) => {
                              return !value?.resource_id ? (
                                <AddPlaylistContentDialog
                                  className="block font-normal"
                                  dialogtriggerLabel={dialogUiConfig.selectMediaBtnLabel}
                                  multiselect={false}
                                  disabled={dataFetching}
                                  apiPrefix={`/api/user/${patientId}/media`}
                                  onSubmit={(errMsg, successMsg) => {
                                    // !TODO: message here
                                    // console.log("onSubmit >>>", errMsg, successMsg); // !DEBUG
                                  }}
                                  onRequestSubmit={(selectedMediaResourceItems) => {
                                    const item = selectedMediaResourceItems.at(0);
                                    if (item) {
                                      onChange({
                                        resource_id: item.id,
                                        category_id: item.category_id,
                                        name: item?.name || item?.linked_resource?.name || ""
                                      });
                                    } 
                                    return Promise.resolve(); // !FIXME
                                  }}
                                />
                              ) : (
                                <div className="flex flex-1 items-center justify-start">
                                  <span className="truncate text-[#858992] text-sm font-light">
                                    {value.name}
                                  </span>
                                  <Button
                                    disabled={dataFetching}
                                    className="px-[18px] focus-visible:ring-0 ml-4"
                                    size={"default"}
                                    variant="secondary"
                                    onClick={() => onChange(null)}
                                  >
                                    <XCircle className="h-4 w-4 mr-1" />
                                    {dialogUiConfig.removeMediaBtnLabel}
                                  </Button>
                                </div>
                              )
                            }}
                          />
                        </FormControl>
                        <div className="flex h-[20px] overflow-hidden">
                          <FormMessage className="truncate" />
                        </div>
                      </FormItem>
                    )}
                  />
                )}
              </div>
            </div>
          </form>
        </Form>
        <DialogCommonFooter
          CustomControls={selectedEvent ? (
            <Button
              disabled={dataFetching}
              className="px-[18px] focus-visible:ring-0"
              size={"default"}
              variant="destructive"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onRemoveEvent();
              }}
            >
              {dialogUiConfig.removeEventBtnLabel}
            </Button>
          ) : undefined}
          className="justify-end"
          closeBtnClassName="mx-4"
          isFetching={dataFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};


export default memo(AddUpdateEventDialog);
