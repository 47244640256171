import {
  FC,
  memo,
  useCallback,
  useState,
} from "react";
import Axios from "axios";
import { t } from "i18next";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import Dropzone from "../../../common/Dropzone";
import { MediaContentDataType } from "@/types";
import AddContentFormSchema from "./AddContentFormSchema";
import { getFilesMediaType } from "./helpers";
import { MEDIA_FILE_STUB_TYPE } from "@/config";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";

export type AddContentDialogPropsType = {
  onOpenClose: (isOpen: boolean) => void;
  apiPrefix: string;
  parentCategoryId?: string;
  selectedContent?: MediaContentDataType,
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
};

const AddContentDialog: FC<AddContentDialogPropsType> = ({
  onOpenClose,
  apiPrefix,
  parentCategoryId,
  selectedContent,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialFileName = typeof selectedContent?.blob_name === "string"
    ? selectedContent.blob_name.split("/").at(-1)
    : undefined;

  const form = useForm<z.infer<typeof AddContentFormSchema>>({
    resolver: zodResolver(AddContentFormSchema),
    defaultValues: {
      fileName: selectedContent?.name ?? "",
      tags: selectedContent?.tags ?? [],
      file: initialFileName
        ? [new File([], initialFileName, { type: MEDIA_FILE_STUB_TYPE })]
        : [],
    },
  });

  const uiConfig = !!selectedContent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const uploadFileToStorage = async (
    file: File,
    mediaContentEntry: MediaContentDataType
  ): Promise<{uploadUrl: string, blobName: string}> => {
      // 1. create SAS url for upload
      const { status: createSasUrlStatus, data: createSasUrlData } = await Axios.post(
        `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource/${mediaContentEntry.id}/blob`,
        { filename: file.name },
      );

      if (createSasUrlStatus !== 201 || 
        !createSasUrlData?.uploadUrl ||
        !createSasUrlData?.blobName
      ) {
        throw new Error("Error creating the SAS Url for the resource");
      }

      // 2. upload a new file by the provided url
      const uploadBlobHeaders = {
        Authorization: null,
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      }

      const { status: uploadBlobStatus } = await Axios.put(
        createSasUrlData.uploadUrl,
        file,
        { headers: uploadBlobHeaders },
      );

      if (uploadBlobStatus !== 201) {
        throw new Error("Error uploading blob");
      }

      return {
        uploadUrl: createSasUrlData?.uploadUrl,
        blobName: createSasUrlData?.blobName
      }
  };


  const onSubmitForm = async (formData: z.infer<typeof AddContentFormSchema>) => {
    try {
      setSubmitFetching(true);

      if (selectedContent) {
        const fileChanged = formData.file[0].name !== selectedContent?.blob_name?.split("/").at(-1); 
        const media_type = fileChanged
          ? getFilesMediaType(formData.file)
          : selectedContent.media_type;

        if (!media_type) {
          throw new Error("Invalid media type");
        }

        // perform updated
        const updatePayload = {
          description: selectedContent.description ?? "",
          icon: selectedContent.icon ?? "",
          preview_url: "", // isn't used
          category_id: parentCategoryId,
          name: formData.fileName,
          tags: formData.tags,
          media_type,
        }

        const { status: updateResStatus } = await Axios.patch(
          `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource/${selectedContent.id}`,
          updatePayload,
        );

        if (updateResStatus !== 200) {
          throw new Error("Error updating resource");
        }

        if (fileChanged) {
          const blobs = formData.file.map((file) => ({
            name: file.name,
            data: file, 
            url: URL.createObjectURL(file),
            size: file.size,
            type: file.type,
            tags: [],
          }));

          const { blobName } = await uploadFileToStorage(blobs[0].data, selectedContent);

          const { status: updateResStatus } = await Axios.patch(
            `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource/${selectedContent.id}/blob/complete`,
            { blobName },
          );
    
          if (updateResStatus !== 200) {
            throw new Error("Error updating resource with blob data");
          }
        }
      } else {
        const media_type = getFilesMediaType(formData.file); // !TODO: check

        if (!media_type) {
          throw new Error("Invalid media type");
        }
  
        const requestPayload = {
          // metadata ???
          tags: formData.tags,
          description: "", // isn't used
          icon: "", // isn't used
          preview_url: "", // isn't used
          category_id: parentCategoryId, // current selected category string | null
          name: formData.fileName,
          media_type,
        }
  
        // 1. create new resource item
        const { status: createResStatus, data: createResData } = await Axios.post(
          `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource`,
          requestPayload,
        );
  
        if (createResStatus !== 201 || !createResData?.id) {
          throw new Error("Error creating new resource");
        }
  
        const blobs = formData.file.map((file) => ({
          name: file.name,
          data: file, 
          url: URL.createObjectURL(file),
          size: file.size,
          type: file.type,
        }));

        const { blobName } = await uploadFileToStorage(blobs[0].data, createResData);
  
        const { status: updateResStatus } = await Axios.patch(
          `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource/${createResData.id}/blob/complete`,
          { blobName },
        );
  
        if (updateResStatus !== 200) {
          throw new Error("Error updating resource with blob data");
        }
      }

      onOpenChange(false);
      onSubmit(null, uiConfig.submitSuccessMessage);
    } catch (err: any) {
      console.error(err);
      onSubmit(uiConfig.submitErrorMessage);
    } finally {
      setSubmitFetching(false);
    }
  };

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault() 
  }, []);


  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>{uiConfig.dialogTriggerLabel}</span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        className="flex flex-col w-[432px] h-[550px]"
      >
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="fileName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="filename"
                  >
                    {dialogUiConfig.nameFieldLabel}
                  </FormLabel>
                  <FormControl id="filename">
                    <Input
                      className="focus-visible:ring-0"
                      id="filename"
                      placeholder={dialogUiConfig.nameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="tags"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="tags">
                    {dialogUiConfig.tagsFieldLabel}
                  </FormLabel>
                  <FormControl id="tags">
                    <Controller
                      {...field}
                      render={({ field: { onChange, onBlur, value }}) => (
                        <TagsMultiSelect
                          onOpenClose={setPopoverOpen}
                          placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
                          defaultSelectedValues={value}
                          disabled={submitFetching}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      //   <CustomMultiSelect
                      //     onOpenClose={setPopoverOpen}
                      //     placeholder={dialogUiConfig.tagsFieldPlaceholder}
                      //     defaultSelectedValues={value}
                      //     data={staticTagsConfig}
                      //     disabled={submitFetching}
                      //     onChange={onChange}
                      //     onBlur={onBlur}
                      // />
                    )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              name="file"
              render={({ field: { value, onChange, ...fieldProps } }) => {
                return (
                  <FormItem className="mb-[4px]">
                    <FormControl>
                      <Dropzone
                        disabled={submitFetching}
                        {...fieldProps}
                        acceptedFiles={form.watch('file')}
                        onRemoveFile={(fileToRemove: File) => {
                          form.setValue(
                            "file",
                            form.getValues("file").filter((file: File) => file !== fileToRemove))
                        }}
                        onChange={onChange}
                      />
                    </FormControl>
                    <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                  </FormItem>
                );
              }}
            />
          </form>
        </Form>
        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddContentDialog);
