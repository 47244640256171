import {
  FC,
  Fragment,
  memo,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import DialogCommonFooter from "../DialogCommonFooter";
import { Loader2, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "../../scroll-area";
import { Input } from "../../input";
import PlaylistContentEntity from "./PlaylistContentEntity";
import {
  MediaCategoryDataType,
  MediaContentDataType,
  MediaContentSortOrderType,
  MediaType,
} from "@/types";
import dialogUiConfig from "./dialogUiConfig";
import { composeMediaTreeData } from "../AddMediaFromCommonLibraryDialog/helpers";
import MediaLibNavigationTree, {
  MediaTreeItem,
  TreeItemType,
} from "./MediaLibNavigationTree";
import SortOrderSelector from "@/components/common/SortOrderSelector";
import {
  checkIfAcceptedMediaResource,
  createMediaLibContentSortFn,
  getMediaLibraryInUseType,
  searchMediaLibraryTreeItems,
} from "./helpers";
import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import { staticMediaContentTypeConfig } from "@/config";
import ActiveFilterValueTrigger from "../../../common/ActiveFilterValueTrigger";
import {
  apiGetMediaLibraryCategories,
  apiGetMediaLibraryResources,
} from "@/api";
import { Checkbox } from "../../checkbox";
import { Label } from "../../label";
import PreviewContentDialog from "../PreviewContentDialog";
import PlaylistCategoryEntity from "./PlaylistCategoryEntity";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";
import { t } from "i18next";

type AddPlaylistContentDialogPropsType = {
  multiselect?: boolean;
  disabled?: boolean;
  apiPrefix: string;
  acceptedMediaTypes?: MediaType[];
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  onRequestSubmit: (
    selectedMediaResourceItems: MediaContentDataType[],
    selectedMediaCategoryItems: MediaCategoryDataType [],
    selectedCustomOptions?: string[]
  ) => Promise<any>;
  onOpenClose?: (isOpen: boolean) => void;
  dialogtriggerLabel?: string;
  DialogTriggerContent?: ReactElement<any>;
  className?: string;
  customOptions?: { id: string; label: string }[];
  includeCategories?: boolean;
};

const AddPlaylistContentDialog: FC<AddPlaylistContentDialogPropsType> = ({
  multiselect = true,
  disabled = false,
  apiPrefix,
  acceptedMediaTypes = [],
  onSubmit,
  onRequestSubmit,
  onOpenClose,
  dialogtriggerLabel = dialogUiConfig.dialogTriggerLabel,
  DialogTriggerContent,
  className = "",
  customOptions,
  includeCategories = false,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMediaItems, setSelectedMediaItems] = useState<
    MediaContentDataType[]
  >([]);
  const [selectedMediaCategories, setSelectedMediaCategories] = useState<
    MediaCategoryDataType[]
  >([]);
  const [dataFetching, setDataDetching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [mediaTreeContent, setMediaTreeContent] =
    useState<MediaTreeItem | null>(null);
  const [selectedMediaTreeItem, setSelectedMediaTreeItem] = useState<
    MediaTreeItem | undefined
  >();
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [filterMediaTypes, setFilterMediaTypes] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<MediaContentSortOrderType>(
    dialogUiConfig.sortMenu[0].id
  );
  const [selectedCustomOptions, setSelectedCustomOptions] = useState<string[]>([]);
  const [previewContentItem, setPreviewContentItem] = useState<MediaContentDataType|null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const mediaLibraryInUseType = getMediaLibraryInUseType(apiPrefix);

  const openContentPreview = (entityData: MediaContentDataType) => {
    setPreviewContentItem(entityData);
  };

  const onOpenChange = useCallback(async (open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }

    const func = open ? initData : cleanupData;
    await func();
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitBtnClick = async () => {
    try {
      setDataDetching(true);
      await onRequestSubmit(
        selectedMediaItems,
        selectedMediaCategories,
        customOptions && selectedCustomOptions
      );
      onOpenChange(false);
      onSubmit(null, dialogUiConfig.submitSuccessMessage);
    } catch (err) {
      console.error(err);
      onSubmit(dialogUiConfig.submitErrorMessage);
    } finally {
      setDataDetching(false);
    }
  };

  const onSelectUnselectMediaCategory = useCallback(
    (entityData: MediaCategoryDataType, isChecked: boolean) => {
      setSelectedMediaCategories(
        isChecked
          ? multiselect ? selectedMediaCategories.concat(entityData) : [entityData] 
          : selectedMediaCategories.filter((item) => item !== entityData)
      );  
    },
    [selectedMediaCategories, multiselect]
  );

  const onSelectUnselectMediaContent = useCallback(
    (entityData: MediaContentDataType, isChecked: boolean) => {
      setSelectedMediaItems(
        isChecked
          ? multiselect ? selectedMediaItems.concat(entityData) : [entityData]
          : selectedMediaItems.filter((item) => item !== entityData)
      );
    },
    [selectedMediaItems, multiselect]
  );

  const initData = async () => {
    try {
      setDataDetching(true);

      const mediaLibraryCategories = await apiGetMediaLibraryCategories(apiPrefix);
      const mediaLibraryResources = await apiGetMediaLibraryResources(apiPrefix);

      // !FIXME
      const commonLibraryCategories = apiPrefix.startsWith("/api/user")
        ? await apiGetMediaLibraryCategories("/api/media") :
        [];
      const commonLibraryResources = apiPrefix.startsWith("/api/user")
        ? await apiGetMediaLibraryResources("/api/media")
        : [];

      const [mediaTreeData] = composeMediaTreeData(
        mediaLibraryCategories,
        mediaLibraryResources,
        commonLibraryCategories,
        commonLibraryResources,
        "id_root",
        dialogUiConfig.mediaLibraryNameByType[mediaLibraryInUseType]
      );

      setMediaTreeContent(mediaTreeData);
      setSelectedMediaTreeItem(mediaTreeData);
    } catch (err) {
      console.error(err);
      // !TODO: onSubmit(dialogUiConfig.submitErrorMessage);
    } finally {
      setDataDetching(false);
    }
  };

  const cleanupData = async () => {
    setSelectedMediaTreeItem(undefined);
    setMediaTreeContent(null);
    setSelectedMediaItems([]);
    setSelectedMediaCategories([]);
    setSearchString("");
    setFilterTags([]);
  };

  const [mediaCategoriesToDisplay, mediaResourcesToDisplay] = useMemo(() => {
    const mediaCategoriesToDisplay: MediaCategoryDataType[] = [];
    const mediaResourcesToDisplay: MediaContentDataType[] = [];

    if (!mediaTreeContent) {
      return [mediaCategoriesToDisplay, mediaResourcesToDisplay];
    }

    // start search mode if a search string / tags or both are present
    const usedMediaTreeItemsData = ((searchString.length > 0 || filterTags.length > 0 || filterMediaTypes.length !== 0))
      ? searchMediaLibraryTreeItems(mediaTreeContent, ({ type, payload }) => {
          if (searchString.length > 0
            && !payload?.name?.toLowerCase()?.includes(searchString.toLowerCase())
            && !payload?.linked_resource?.name?.toLowerCase()?.includes(searchString.toLowerCase())
          ) {
            return false;
          }

          if (filterMediaTypes.length !== 0
            && (type !== TreeItemType.TYPE_RESOURCE
            || (type === TreeItemType.TYPE_RESOURCE
            && !filterMediaTypes.includes(payload?.media_type)
            && !filterMediaTypes.includes(payload?.linked_resource?.media_type)))
          ) {
            return false;
          }

          if (filterTags.length > 0
            && (type === TreeItemType.TYPE_CATEGORY
            || (type === TreeItemType.TYPE_RESOURCE
            && !filterTags.every((tag: string) => payload?.tags?.includes?.(tag))
            && !filterTags.every((tag: string) => payload?.linked_resource?.tags?.includes?.(tag))))
          ) {
            return false;
          }

          return true;
        })
      : (selectedMediaTreeItem?.children || []);

    for (let mediaTreeItem of usedMediaTreeItemsData) {
      const { type, payload } = mediaTreeItem;

      if (type === TreeItemType.TYPE_ROOT) continue;
      if (type === TreeItemType.TYPE_CATEGORY && !includeCategories) continue;
      if (type === TreeItemType.TYPE_RESOURCE
        && (payload.media_type === "shared_category"
          || (payload.media_type === "shared_resource" && !payload.linked_resource))) {
        continue;
      }
      
      let destResultArr = (type === TreeItemType.TYPE_CATEGORY)
       ? mediaCategoriesToDisplay
       : mediaResourcesToDisplay;

      if (type === TreeItemType.TYPE_RESOURCE
        && !checkIfAcceptedMediaResource(mediaTreeItem, acceptedMediaTypes)) {
        continue;
      }

      // if (type === TreeItemType.TYPE_RESOURCE
      //   && filterMediaTypes.length !== 0
      //   && filterMediaTypes.includes(payload?.media_type)) {
      //   continue;
      // }

      destResultArr.push(payload);
    }

    const sortFn = createMediaLibContentSortFn(sortOrder);

    mediaCategoriesToDisplay.sort(sortFn);
    mediaResourcesToDisplay.sort(sortFn);

    return [
      mediaCategoriesToDisplay,
      mediaResourcesToDisplay,
    ];
  }, [
    searchString,
    mediaTreeContent,
    selectedMediaTreeItem,
    sortOrder,
    filterTags,
    filterMediaTypes,
    includeCategories,
    acceptedMediaTypes,
  ]);

  const selectedCounterString = (selectedMediaItems.length > 0
    || selectedMediaCategories.length > 0)
    ? ` (${selectedMediaItems.length + selectedMediaCategories.length})` : "";

  const selectedFolderName = useMemo(() => {
    if (searchString.length > 0 || filterTags.length > 0) {
      return dialogUiConfig.searchResultsTitle;
    }

    if (!selectedMediaTreeItem) {
      return "";
    }

    return selectedMediaTreeItem?.payload?.name;
  }, [selectedMediaTreeItem, searchString, filterTags]);

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        className={className}
        disabled={disabled}
      >
        {DialogTriggerContent || (
          <Button
            type="button"
            disabled={disabled}
            className="px-[18px] focus-visible:ring-0"
            size={"default"}
            variant="secondary"
          >
            <PlusCircle className="h-4 w-4 mr-1" />
            {dialogtriggerLabel}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        hideDefaultCloseBtn
        className="flex flex-col w-11/12 sm:max-w-full sm:max-h-full h-5/6"
      >
        {dataFetching && (
          <Loader2
            className={
              "h-16 w-16 text-primary/60 animate-spin absolute z-10 top-1/2 left-1/2"
            }
          />
        )}
        <DialogHeader>
          <DialogTitle>
            <div className="flex flex-row justify-between items-center">
              <span className="leading-8 font-semibold text-2xl text-[#11203D]">
                {dialogUiConfig.dialogHeaderTitle}
              </span>
              <div className="items-center flex">
                {/* FILTER BY MEDIA TYPE */}
                {acceptedMediaTypes.length !== 1 && (
                  <CustomMultiSelect
                    onOpenClose={setPopoverOpen}
                    placeholder={dialogUiConfig.mediaTypeFilterTriggerLabel}
                    defaultSelectedValues={[]}
                    data={staticMediaContentTypeConfig}
                    disabled={dataFetching}
                    className="px-[10px] w-auto ml-[12px] border-dashed"
                    onChange={setFilterMediaTypes}
                    TriggerContentComponent={ActiveFilterValueTrigger}
                    clearSelectionBtnLabel={
                      dialogUiConfig.mediaTypeFilterClearBtn
                    }
                  />
                )}
                {/* FILTER BY TAGS */}
                {/* <CustomMultiSelect
                  onOpenClose={setPopoverOpen}
                  placeholder="Tags"
                  className="px-[10px] w-auto border-dashed ml-[12px] "
                  defaultSelectedValues={[]}
                  data={staticTagsConfig}
                  disabled={dataFetching}
                  onChange={setFilterTags}
                  TriggerContentComponent={ActiveFilterValueTrigger}
                /> */}
                <TagsMultiSelect
                  onOpenClose={setPopoverOpen}
                  placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
                  className="px-[10px] w-auto border-dashed ml-[12px] "
                  defaultSelectedValues={filterTags}
                  disabled={dataFetching}
                  onChange={setFilterTags}
                  TriggerContentComponent={ActiveFilterValueTrigger}
                />
                <SortOrderSelector
                  disabled={dataFetching}
                  className="hover:bg-accent hover:text-accent-foreground"
                  values={dialogUiConfig.sortMenu}
                  defaultValue={sortOrder}
                  onValueChange={(val) =>
                    setSortOrder(val as MediaContentSortOrderType)
                  }
                />
                <Input
                  disabled={dataFetching}
                  type="search"
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder={dialogUiConfig.filterByNameInputPlaceholder}
                  className="focus-visible:ring-0 max-w-xs placeholder-gray-500 text-[#11203D] ml-[30px] font-medium w-[248px]"
                />
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-1 h-full w-full overflow-hidden">
          {(searchString.length === 0
            && filterTags.length === 0
            && filterMediaTypes.length === 0
          ) && (
            <ScrollArea className="w-64 px-0 py-[8px] min-w-[250px] border-r-[1px] border-[#E0E0E0]">
              {mediaTreeContent && (
                <MediaLibNavigationTree
                  selectedItem={selectedMediaTreeItem}
                  onItemSelected={setSelectedMediaTreeItem}
                  data={mediaTreeContent}
                />
              )}
            </ScrollArea>
          )}
          <div className="flex flex-1 h-full">
            <div className="flex flex-col grow-[3]">
              <div className="px-[24px] mb-[4px]">
                <span className="font-medium text-base leading-5">
                  {selectedFolderName}
                </span>
              </div>
              <ScrollArea className="w-full">
                <div className="grid auto-fill-[180px] gap-2 px-[24px] py-[8px] min-w-[640px]">
                  {mediaCategoriesToDisplay.map((mediaCategoryToDisplay) => {
                    return mediaCategoryToDisplay && (
                      <PlaylistCategoryEntity
                        key={mediaCategoryToDisplay.id}
                        entityData={mediaCategoryToDisplay}
                        // isPackage={false} // !FIXME
                        isSelected={selectedMediaCategories.includes(mediaCategoryToDisplay)}
                        actionsMenuEnabled={false}
                        onClick={() => {}} // stub
                        onCheck={onSelectUnselectMediaCategory}
                      />
                    );
                  })}
                  {mediaResourcesToDisplay.map((mediaResourceToDisplay) => {
                    return mediaResourceToDisplay && (
                      <PlaylistContentEntity
                        key={mediaResourceToDisplay.id}
                        entityData={mediaResourceToDisplay}
                        isPackage={false} // !FIXME
                        isSelected={selectedMediaItems.includes(mediaResourceToDisplay)}
                        actionsMenuEnabled={false}
                        onClick={openContentPreview}
                        onCheck={onSelectUnselectMediaContent}
                      />
                    );
                  })}
                </div>
              </ScrollArea>
            </div>
            {/* === selected resources === */}
            {(selectedMediaItems.length > 0 || selectedMediaCategories.length > 0) && (
              <div className="flex flex-col grow-[1] border-l-[1px] border-[#E0E0E0]">
                <div className="px-[24px] mb-[4px]">
                  <span className="font-medium text-base leading-5">
                    {dialogUiConfig.selectedContentSectionHeader}
                  </span>
                </div>
                <ScrollArea className="w-full">
                  <div className="grid auto-fill-[180px] gap-2 px-[24px] py-[8px] min-w-[416px]">

                    {selectedMediaCategories.map((selectedMediaCategory) => {
                      return (
                        <PlaylistCategoryEntity
                          key={selectedMediaCategory.id}
                          entityData={selectedMediaCategory}
                          isSelected={selectedMediaCategories.includes(selectedMediaCategory)}
                          actionsMenuEnabled={false}
                          onClick={() => {}} // stub
                          onCheck={(mediaCategory) => {
                            setSelectedMediaCategories(
                              selectedMediaCategories.filter(
                                (item) => item !== mediaCategory
                              )
                            );
                          }}
                        />
                      );
                    })}
                    {selectedMediaItems.map((mediaContentItem) => {
                      return (
                        <PlaylistContentEntity
                          entityData={mediaContentItem}
                          isPackage={false} // !FIXME
                          isSelected={selectedMediaItems.includes(
                            mediaContentItem
                          )}
                          actionsMenuEnabled={false}
                          onClick={openContentPreview}
                          onCheck={(entityData) => {
                            setSelectedMediaItems(
                              selectedMediaItems.filter(
                                (item) => item !== entityData
                              )
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>
        </div>
        <PreviewContentDialog
          selectedMediaContent={previewContentItem}
          setSelectedMediaContent={setPreviewContentItem}
        />
        <DialogCommonFooter
          CustomControls={
            customOptions && (
              <div className="px-[250px] flex flex-1 items-center justify-start overflow-hidden">
                {customOptions.map(({ id, label }) => (
                  <Fragment>
                    <Checkbox
                      checked={selectedCustomOptions.includes(id)}
                      onClick={() => {
                        setSelectedCustomOptions(
                          selectedCustomOptions.includes(id)
                            ? selectedCustomOptions.filter(
                                (optionId) => optionId !== id
                              )
                            : selectedCustomOptions.concat(id)
                        );
                      }}
                      id={id}
                      aria-label="select item"
                      className="focus-visible:ring-0 mr-[8px] ml-[16px] border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
                    />
                    <Label
                      htmlFor={id}
                      className="w-auto cursor-pointer font-normal text-sm text-[#11203D]"
                    >
                      {label}
                    </Label>
                  </Fragment>
                ))}
              </div>
            )
          }
          submitBtnClassName="ml-[16px]"
          className="border-0 border-slate-200 justify-end"
          isFetching={dataFetching} // !TODO: rename property to disabled
          // selectedMediaItems.length
          onSubmitBtnClick={onSubmitBtnClick}
          submitBtnLabel={dialogUiConfig.submitBtnLabel + selectedCounterString}
          cancelBtnLabel={dialogUiConfig.cancelBtnLabel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddPlaylistContentDialog);
