import Axios from "axios";
import {
  MediaCategoryDataType,
  MediaContentDataType,
  MediaType,
  PatientSettingsType,
} from "./types";
import { PatientSettingsFormSchema } from "./config";

// !TODO: name from common library
const apiImportMediaLibraryContent = async (
  mediaResourceItems: MediaContentDataType[],
  mediaCategoryItems: MediaCategoryDataType[],
  apiPrefix: string,
  copyLinkedPlaylist: boolean = false,
) => {
  for (const mediaResourceItem of mediaResourceItems) {
    const isPlaylist = mediaResourceItem.media_type === "playlist";

    const result = isPlaylist && copyLinkedPlaylist
      ? await Axios.post(`${apiPrefix}/category/root/copy_playlist`, {
          resource_id: mediaResourceItem.id,
        })
      : await Axios.post(`${apiPrefix}/category/root/resource`, {
          media_type: "shared_resource",
          linked_resource_id: mediaResourceItem.id,
          copy_linked_playlist: copyLinkedPlaylist,
        });

    if (result.status !== 201) {
      throw new Error("Error importing resources");
    }
  }

  for (const mediaCategoryItem of mediaCategoryItems) {
    const result = await Axios.post(`${apiPrefix}/category/root/resource`, {
      media_type: "shared_category",
      linked_resource_id: mediaCategoryItem.id,
      copy_linked_playlist: copyLinkedPlaylist,
    });

    if (result.status !== 201) {
      throw new Error("Error importing categories");
    }
  }
};

const apiAddPlaylistItems = async (
  mediaResourceItems: MediaContentDataType[],
  firstIndexToAdd: number,
  apiPrefix: string,
  playlistId: string,
  categoryId?: string | null,
) => {
  let sort_order = firstIndexToAdd;
      
  for (const { id: resource_id} of mediaResourceItems) {
    await Axios.post(
      `${apiPrefix}/category/${categoryId ?? "root"}/resource/${playlistId}/playlist`,
      { resource_id, sort_order }
    );

    sort_order += 1;
  }
};


const apiGetMediaLibraryCategories = async (apiPrefix?: string) => {
  const categoriesData: MediaCategoryDataType[] = (
    await Axios.get(`${apiPrefix}/category/common`)
  )?.data;

  if (!categoriesData) {
    throw new Error("error getting resources categories data");
  }

  return categoriesData;
};

const apiGetMediaLibraryResources = async (
  apiPrefix: string,
  filters?: Partial<MediaContentDataType & { media_type?: MediaType[]}>,
  includeLinks?: boolean,
) => {
  const resourcesData: MediaContentDataType = (
    await Axios.get(`${apiPrefix}/category/resource/common`, { params: { filters, includeLinks } })
  )?.data;

  if (!resourcesData) {
    throw new Error("error getting resources categories data");
  }

  return resourcesData;
};

const apiGetPatientSettings = async (patientId: string) => {
  const responseData = (await Axios.get(`/api/patient/${patientId}/settings`))?.data;
  const responseParsed = PatientSettingsFormSchema.safeParse(responseData);

  let result = responseParsed.success
    ? responseParsed.data as PatientSettingsType
    : null;

  return result;
};

const apiUpdatePatientSettings = async(
  patientId: string,
  settings: PatientSettingsType,
) => {
  const result = (await Axios.put(`/api/patient/${patientId}/settings`, {
    settings,
  }))?.data;

  return result;
};


export {
  apiImportMediaLibraryContent,
  apiAddPlaylistItems,
  apiGetMediaLibraryCategories,
  apiGetMediaLibraryResources,
  apiGetPatientSettings,
  apiUpdatePatientSettings,
};
