import { t } from "i18next";
import {
  GameType,
  MediaContentSortOrderType,
  MediaType,
} from "./types";
import { GroupItemType, GroupsCategoryType, GroupType } from "./components/common/CustomMultiSelect";
import { z } from "zod";

const MAX_MEDIA_FILE_SIZE_MB = 512;
// is used to passthrough form validation (update content)
const MEDIA_FILE_STUB_TYPE = "file/stub";

const allowedMimeTypes = [
  "application/pdf",
  "video/mp4",
  MEDIA_FILE_STUB_TYPE,
];

const staticMediaContentTypeConfig: {
  groupId: string;
  groupLabel: string;
  items: { id: MediaType; label: string; }[];
} [] = [
  {
    groupId: "idContentType",
    groupLabel: t("common.mediaContentTypes.mediaTypesGroup"),
    items: [
      { id: "video", label: t("common.mediaContentTypes.video") },
      { id: "document", label: t("common.mediaContentTypes.document") },
      { id: "game", label: t("common.mediaContentTypes.game") },
      { id: "playlist", label: t("common.mediaContentTypes.playlist") },
      { id: "external_source", label: t("common.mediaContentTypes.externalSource") },
    ],
  },
];

const staticTagsConfig: (GroupsCategoryType<string> | GroupType<string> | GroupItemType<string>)[] = [
  {
    categoryId: "idCategoryTraining",
    categoryLabel: t("common.mediaTags.trainingCategory"),
    items: [
      {
        groupId: "idTrainingLvl",
        groupLabel: t("common.mediaTags.trainingLvlGroup"),
        items: [
          { id: "trainingLvlEasy", label: t("common.mediaTags.trainingLvlEasy") },
          { id: "trainingLvlMedium", label: t("common.mediaTags.trainingLvlMedium") },
          { id: "trainingLvlAdvanced", label: t("common.mediaTags.trainingLvlAdvanced") },
        ],
      },
      {
        groupId: "idClassType",
        groupLabel: t("common.mediaTags.classTypeGroup"),
        items: [
          { id: "classLowerBody", label: t("common.mediaTags.classLowerBody") },
          { id: "classFullBody", label: t("common.mediaTags.classFullBody") },
          { id: "classUpperBody", label: t("common.mediaTags.classUpperBody") },
        ],
      },
      {
        groupId: "idTrainingType",
        groupLabel: t("common.mediaTags.trainigTypeGroup"),
        items: [
          { id: "trainingTypeStrength", label: t("common.mediaTags.trainingTypeStrength") },
          { id: "trainingTypeBalance", label: t("common.mediaTags.trainingTypeBalance") },
          { id: "trainingTypeMobility", label: t("common.mediaTags.trainingTypeMobility") },
        ],
      },
      {
        groupId: "idPosition",
        groupLabel: t("common.mediaTags.positionGroup"),
        items: [
          { id: "positionSitting", label: t("common.mediaTags.positionSitting") },
          { id: "positionStanding", label: t("common.mediaTags.positionStanding") },
          { id: "positionSittingStanding", label: t("common.mediaTags.positionSittingStanding") },
        ],
      },
    ]
  },
  { id: "learning", label: t("common.mediaTags.learning") },
  { id: "entertaiment", label: t("common.mediaTags.entertaiment") },
];

const staticGamesConfig: {
  gameType: GameType;
  label: string;
} [] = [
  {
    gameType: "https://app.neuronup.com/play/activity/game/81/en_US",
    label: "Defeat the Monster",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/90/en_US",
    label: "Mole Invasion",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/131/en_US",
    label: "The Builder",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/79/en_US",
    label: "Card Pyramid",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/62/en_US",
    label: "Sorting Bugs",
  },
];

const sortLabelsConfig = {
  [MediaContentSortOrderType.SORT_BY_NAME]: t("common.sortOptions.byName"),
  [MediaContentSortOrderType.SORT_BY_DATE]: t("common.sortOptions.byDate"),
  [MediaContentSortOrderType.SORT_BY_MEDIA_TYPE]: t("common.sortOptions.byType"),
};

// !TODO
export type TagItemType = {
  type: "tag_item";
  id: string;
  label: string;
};

// !TODO
export type TagsGroupType = {
  type: "tags_group";
  id: string;
  label: string;
  items?: TagItemType[];
};

// !TODO
export type TagCategoryType = {
  type: "tag_category";
  id: string;
  label: string;
  items?: (TagItemType | TagsGroupType)[]; 
};

// !TODO
export type TagsConfigType = (TagItemType | TagsGroupType | TagCategoryType)[];

const staticTagsConfig2: TagsConfigType = [
  {
    type: "tag_category",
    id: "idCategoryTraining",
    label: t("common.mediaTags.trainingCategory"),
    items: [
      {
        type: "tags_group",
        id: "idTrainingLvl",
        label: t("common.mediaTags.trainingLvlGroup"),
        items: [
          { type: "tag_item", id: "trainingLvlEasy", label: t("common.mediaTags.trainingLvlEasy") },
          { type: "tag_item", id: "trainingLvlMedium", label: t("common.mediaTags.trainingLvlMedium") },
          { type: "tag_item", id: "trainingLvlAdvanced", label: t("common.mediaTags.trainingLvlAdvanced") },
        ],
      },
      {
        type: "tags_group",
        id: "idClassType",
        label: t("common.mediaTags.classTypeGroup"),
        items: [
          { type: "tag_item", id: "classLowerBody", label: t("common.mediaTags.classLowerBody") },
          { type: "tag_item", id: "classFullBody", label: t("common.mediaTags.classFullBody") },
          { type: "tag_item", id: "classUpperBody", label: t("common.mediaTags.classUpperBody") },
        ],
      },
      {
        type: "tags_group",
        id: "idTrainingType",
        label: t("common.mediaTags.trainigTypeGroup"),
        items: [
          { type: "tag_item", id: "trainingTypeStrength", label: t("common.mediaTags.trainingTypeStrength") },
          { type: "tag_item", id: "trainingTypeBalance", label: t("common.mediaTags.trainingTypeBalance") },
          { type: "tag_item", id: "trainingTypeMobility", label: t("common.mediaTags.trainingTypeMobility") },
        ],
      },
      {
        type: "tags_group",
        id: "idPosition",
        label: t("common.mediaTags.positionGroup"),
        items: [
          { type: "tag_item", id: "positionSitting", label: t("common.mediaTags.positionSitting") },
          { type: "tag_item", id: "positionStanding", label: t("common.mediaTags.positionStanding") },
          { type: "tag_item", id: "positionSittingStanding", label: t("common.mediaTags.positionSittingStanding") },
        ],
      },
    ]
  },
  {
    type: "tag_category",
    id: "learning", // !TODO
    label: t("common.mediaTags.learning"),
    items: [],
  },
  {
    type: "tag_category",
    id: "entertaiment", // !TODO
    label: t("common.mediaTags.entertaiment"),
    items: [],
  },
];

const PatientSettingsFormSchema = z.object({
  call_back: z.boolean().default(false),
  view_messages: z.boolean().default(false),
  reply_messages: z.boolean().default(false),
  autoplay_video: z.boolean().default(true),
  display_start_time: z.boolean().default(true),
  display_finish_time: z.boolean().default(true),
  manage_calendar: z.boolean().default(false),
  main_screen_type: z
    .enum(["default", "contrast"])
    .default("default"),
  display_activities_mode: z
    .enum(["full_schedule", "last_3_activities"])
    .default("full_schedule"),
  night_time_range: z.object({
    from: z.object({
      hour: z.number().nonnegative(),
      minute: z.number().nonnegative()
    }),
    to: z.object({
      hour: z.number().nonnegative(),
      minute: z.number().nonnegative()
    })
  }).default({
    from: { hour: 22, minute: 0 },
    to: { hour: 7, minute: 0 },
  })
});

const patientSettingsDefault = PatientSettingsFormSchema.parse({});

const customIconsConf = {
  "engelsk": "/icons/custom_categories/engelsk.jpg",
  "friminutt": "/icons/custom_categories/friminutt.jpg",
  "gym": "/icons/custom_categories/gym.jpg",
  "jobbe": "/icons/custom_categories/jobbe.jpg",
  "kunst": "/icons/custom_categories/kunst.jpg",
  "matte": "/icons/custom_categories/matte.jpg",
  "musikk": "/icons/custom_categories/musikk.jpg",
  "norsk": "/icons/custom_categories/norsk.jpg",
  "pause": "/icons/custom_categories/pause.jpg",
  "samling": "/icons/custom_categories/samling.jpg",
  "rosa": "/icons/custom_categories/rosa.png",
  "ikon1": "/icons/custom_categories/ikon1.png",
  "ikon2": "/icons/custom_categories/ikon2.png",
};

const customIconsNames = Object.keys(customIconsConf); // !TODO: proper type

export {
  customIconsNames,
  customIconsConf,
  patientSettingsDefault,
  PatientSettingsFormSchema,
  MAX_MEDIA_FILE_SIZE_MB,
  MEDIA_FILE_STUB_TYPE,
  allowedMimeTypes,
  sortLabelsConfig,
  staticTagsConfig,
  staticMediaContentTypeConfig,
  staticGamesConfig,
  staticTagsConfig2,
};