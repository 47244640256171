import { FC, memo, useState } from "react";
import { t } from "i18next";
import { Plus, Import } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import AddContentDialog from "../../ui/dialog/AddContentDialog";
import AddCategoryDialog from "../../ui/dialog/AddCategoryDialog";
import AddUpdatePlaylistDialog from "../../ui/dialog/AddUpdatePlaylistDialog";
import AddUpdateExtSourceDialog from "../../ui/dialog/AddUpdateExtSourceDialog";
import AddPlaylistContentDialog from "@/components/ui/dialog/AddPlaylistContentDialog";
import dialogUiConfig from "@/components/ui/dialog/AddPlaylistContentDialog/dialogUiConfig";
import { apiImportMediaLibraryContent } from "@/api";
import AddUpdateGameDialog from "@/components/ui/dialog/AddUpdateGameDialog";

type MediaLibraryActionsDropdownPropType = {
  isPackage: boolean; // inside a package
  apiPrefix: string;
  isPrivateLibrary: boolean;
  disabled: boolean;
  parentCategoryId?: string;
  onSubmitCategory: (errMsg: string | null, successMsg?: string) => void;
  onSubmitContent: (errMsg: string | null, successMsg?: string) => void;
};

const MediaLibraryActionsDropdown: FC<MediaLibraryActionsDropdownPropType> = ({
  isPackage,
  apiPrefix,
  isPrivateLibrary,
  disabled,
  parentCategoryId,
  onSubmitCategory,
  onSubmitContent,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dialogCommonProps = {
    disabled: false, // TODO
    apiPrefix,
    parentCategoryId,
    selectedCategory: undefined,
    selectedContent: undefined,
    onOpenClose: setDropdownOpen,
  };

  return (
    <DropdownMenu
      modal={false}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger disabled>
            <DropdownMenuTrigger disabled={disabled}>
              <Button
                disabled={disabled}
                className="px-[18px]"
                aria-haspopup="true"
                size="default"
              >
                <Plus className="h-4 w-4 mr-1" />
                {t("common.add")}
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          {disabled && (
            <TooltipContent className="text-[#FFFFFF] bg-[#18181B]">
              {t("mediaLibraryScreen.packageEditDisabledTooltip")}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent className="w-[210px]" align="end">
        {/* !TODO: refactor old dialog */}
        {/* {isPackage && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              onAddCategory();
            }}
          >
            <FilePlus className="h-4 w-4 mr-1" />
            {t("mediaLibraryScreen.newCategory")}
          </DropdownMenuItem>
        )} */}
        {/* !TODO: refactor old dialog */}
        {/* {isPackage && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              onAddContent();
            }}
          >
            <FolderPlus className="h-4 w-4 mr-1" />
            {t("mediaLibraryScreen.newContent")}
          </DropdownMenuItem>
        )} */}
        {/* !TODO: remove the old implementation */}
        {/* {isPrivateLibrary && (
          <DropdownMenuDialogItem
            dialogItemProps={{
              apiPrefix: apiPrefix,
              onSubmit: onImportContent,
            }}
            DialogItem={AddMediaFromCommonLibraryDialog}
          />
        )} */}
        {isPrivateLibrary && (
          <AddPlaylistContentDialog
            includeCategories
            {...dialogCommonProps}
            apiPrefix={"/api/media"}
            className="p-0 w-full"
            customOptions={[
              {
                id: "idCopyToLib",
                label: dialogUiConfig.optionCopyToLibrary,
              },
            ]}
            DialogTriggerContent={
              <DropdownMenuItem
                disabled={disabled}
                className="w-full flex py-0 px-[8px]"
                onSelect={(e) => e.preventDefault()}
              >
                <div className="flex flex-row items-center w-full h-[32px]">
                  <Import className="h-4 w-4 mr-[8px]" />
                  <span>{dialogUiConfig.dialogTriggerLabel2}</span>
                </div>
              </DropdownMenuItem>
            }
            onRequestSubmit={async (
              selectedItems,
              selectedCategories,
              selectedCustomOptions
            ) => {
              return await apiImportMediaLibraryContent(
                selectedItems,
                selectedCategories,
                apiPrefix,
                selectedCustomOptions?.includes("idCopyToLib")
              );
            }}
            onSubmit={onSubmitContent} // !FIXME
          />
        )}
        <AddContentDialog {...dialogCommonProps} onSubmit={onSubmitContent} />
        <AddCategoryDialog {...dialogCommonProps} onSubmit={onSubmitCategory} />
        <AddUpdatePlaylistDialog
          {...dialogCommonProps}
          onSubmit={onSubmitContent}
        />
        <AddUpdateExtSourceDialog
          {...dialogCommonProps}
          onSubmit={onSubmitContent}
        />
        <AddUpdateGameDialog
          {...dialogCommonProps}
          onSubmit={onSubmitContent}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(MediaLibraryActionsDropdown);
