import { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { useParams, useLocation } from "react-router-dom";
import { Profile } from "./profile";
import { Schedule } from "./schedule";
import PatientSettings from "./PatientSettings/index";
import { FamilyMembers } from "./FamilyMembers/index";
import { MediaLibrary } from "./mediaLibrary";
import { Doctors } from "./doctors";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import ChatComponent from "@/components/ui/chat/ChatComponent";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useUnreadMessagesCount from "app/hooks/useUnreadMessagesCount";
import { Badge } from "@/components/ui/badge";
import { AppContext } from "app/providers/stateProvider";
import { useSettings } from "app/hooks/settings";
import { AuthContext } from "app/providers/auth";
import { useError } from "app/utils";

export function Patient() {
  const authContext = useContext(AuthContext);
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const [settings] = useSettings();
  const [onlineStatus, setOnlineStatus] = useState({
    online: false,
    batteryLevel: 0,
  });
  const params = useParams();
  const location = useLocation();
  const [handleError] = useError();

  useEffect(() => {
    const fetchOnlineStatus = async () => {
      try {
        const req = await Axios.get(`/api/device/status/${params.patientId}`);
        const status = req.data;
        setOnlineStatus({
          online: status.online,
          batteryLevel: status.battery_level,
        });
      } catch (error) {
        console.error(error);
        handleError(error);
      }
    };

    fetchOnlineStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.patientId]);

  const canViewFamily =
    authContext.user?.permission === "owner" ||
    authContext.user?.permission === "master" ||
    authContext.user?.permission === "admin" ||
    authContext.user?.permission === "doctor" ||
    authContext.user?.permission === "familyMember";

  const canViewDoctors =
    authContext.user?.permission === "owner" ||
    authContext.user?.permission === "master" ||
    authContext.user?.permission === "admin";

  const canViewSettings =
    authContext.user?.permission === "owner" ||
    authContext.user?.permission === "master" ||
    authContext.user?.permission === "admin" ||
    authContext.user?.permission === "doctor";

  const unreadCount = useUnreadMessagesCount(
    appContext.chat.chatState.selectedRoomId
  );

  const [selectedTab, setSelectedTab] = useState("calendar");

  const activeTabContainerCommon = "flex flex-col h-full max-h-full"; 

  return (
    <main className="flex flex-col flex-1 h-full w-full overflow-hidden">
      <Profile
        name={location.state.name}
        online={onlineStatus.online}
        batteryLevel={onlineStatus.batteryLevel}
        patientId={params.patientId}
      />
      <Tabs
        onValueChange={setSelectedTab}
        className="flex flex-col p-0 h-full mt-2 overflow-hidden"
        defaultValue={selectedTab}
      >
        <div>
          <TabsList className="justify-start ml-4">
            <TabsTrigger value="calendar">
              {t("patientsScreen.tabs.schedule")}
            </TabsTrigger>
            <TabsTrigger value="medialibrary">
              {t("patientsScreen.tabs.mediaLibrary")}
            </TabsTrigger>
            {canViewFamily && (
              <TabsTrigger value="permissionFamily">
                {t("patientsScreen.tabs.family")}
              </TabsTrigger>
            )}
            {canViewDoctors && (
              <TabsTrigger value="permissionDoctors">
                {t("patientsScreen.tabs.doctors")}
              </TabsTrigger>
            )}
            {canViewSettings && (
              <TabsTrigger value="patientSettins">
                {t("patientsScreen.tabs.settings")}
              </TabsTrigger>
            )}
            {settings.enable_chat_feature && (
              <TabsTrigger value="chat">
                <div>
                  <span>{t("patientsScreen.tabs.chat")}</span>
                  {unreadCount > 0 && (
                    <Badge variant="destructive" className="ml-2">
                      {unreadCount < 100 ? unreadCount : "99+"}
                    </Badge>
                  )}
                </div>
              </TabsTrigger>
            )}
          </TabsList>
        </div>
        
        <TabsContent
          className={selectedTab === "chat"
            && `${activeTabContainerCommon} overflow-hidden`}
          value="chat"
        >
          <ChatComponent userId={params.patientId} />
        </TabsContent>
        <TabsContent
          className={selectedTab === "calendar" &&
            `${activeTabContainerCommon} overflow-hidden`}
          value="calendar"
        >
          <Schedule />
        </TabsContent>
        <TabsContent
          className={selectedTab === "medialibrary"
            && `${activeTabContainerCommon} px-4 overflow-scroll`}
          value="medialibrary"
        >
          <MediaLibrary />
        </TabsContent>
        <TabsContent
          className={selectedTab === "permissionFamily"
            && `${activeTabContainerCommon} px-4 overflow-scroll`}
          value="permissionFamily"
        >
          <FamilyMembers />
        </TabsContent>
        <TabsContent
          className={selectedTab === "permissionDoctors"
            && `${activeTabContainerCommon} px-4 overflow-scroll`}
          value="permissionDoctors"
        >
          <Doctors />
        </TabsContent>
        <TabsContent
          className={selectedTab === "patientSettins"
            && `${activeTabContainerCommon} overflow-hidden`}
          value="patientSettins"
        >
          <PatientSettings/>
        </TabsContent>
      </Tabs>
    </main>
  );
};
